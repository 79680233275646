import React from "react"
import SEO from "../components/seo"
// import { Helmet } from 'react-helmet'
import Layout from "../components/layout"
import FreeTrial from '../components/free-trial'
import TwoColumn from "../components/two-column"
import ComparisonTable from "../components/comparison-table"
import Testimonial from '../components/testimonial'
import SwiperTabs from '../components/swiper-tabs'
import PlanAction from '../components/plan-action'
import CustomerJourney from '../components/customer-journey'
import BadgeContent from "../components/badge-component"
// import GetStarted from '../components/get-started'
import {
  bannerContent, 
  comparisonTableContent,
  trialContent,
  planActionContent,
  customerJourneyContent,
  developersContent,
  // getStartedContent
} from "../lib/home-content"
import { swiperTabsContent } from "../lib/swiper-tabs-content"
// import { testimonialContent } from "../lib/testimonial-content"

const badgeData = {
  section: "home",
  link: true,
  FallBadges: [
    {
      img: require("../images/high-performance.svg"),
      altText: 'high-performance',
    },
    {
      img: require("../images/easiest-to-do-bussiness-with-fall.svg"),
      altText: 'easiest-to-do-bussiness-with-fall',
    },
    {
      img: require("../images/fastest-implementation-fall.svg"),
      altText: 'fastest-implementation-fall',
    },
    {
      img: require("../images/easiest-setup-fall.svg"),
      altText: 'easiest-setup-fall',
    },
    {
      img: require("../images/best-est-roi-fall.svg"),
      altText: 'best-est-roi-fall',
    },
    {
      img: require("../images/user-most-likely-to-recommend-fall.svg"),
      altText: 'user-most-likely-to-recommend-fall',
    },
  ],
}

const IndexPage = () => (
  <Layout isHeaderTransparent={true}>
    <SEO 
      title="Billsby | Subscription billing software for recurring payments | $5k free trial" 
      description="Rely on Billsby for easy and accurate recurring payments. The most powerful, customizable and easy-to-integrate subscription billing software." 
      url="https://www.billsby.com/"
    />

      <div className="home-banner-with-badge">
        <TwoColumn content={bannerContent}/>
        <BadgeContent content={badgeData} />
      </div>
     <SwiperTabs 
      tabs={swiperTabsContent} 
      title="If you can dream it, we can bill it." 
      description="Every Billsby account supports unlimited products, plans and cycles - making it really easy to support even the most complex plans." 
     />
     <PlanAction content={planActionContent} />
     <CustomerJourney content={customerJourneyContent} />
     <ComparisonTable content={comparisonTableContent}/>
     <TwoColumn content={developersContent}/>
     {/* <GetStarted content={getStartedContent} /> */}
     <Testimonial/>
     <FreeTrial content={trialContent}/>
  </Layout>
)

export default IndexPage
